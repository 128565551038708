
<template>
  <div class="tabWrapper">
    <v-overlay :value="isOverlayVisible">
      <v-progress-circular
        :size="70"
        :width="7"
        color="amber"
        indeterminate
        class="spinner"
      />
    </v-overlay>
    <v-container fluid>
      <v-row>
        <v-col
          cols="12"
          md="4"
          offset-md="8"
          offset-lg="8"
          class="actionBarDateRangePickerWrapper"
        >
          <date-range-picker
            ref="picker"
            v-model="dateRange"
            opens="left"
            :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY HH:mm:ss' }"
            :max-date="new Date()"
            :show-week-numbers="true"
            :show-dropdowns="true"
            :auto-apply="true"
            @update="updateValues"
            @toggle="checkOpen"
          >
            <template
              v-slot:input="picker"
            >
              <div class="datePicker">
                <span v-if="picker.startDate && picker.endDate">
                  {{ moment(picker.startDate).format('MM-DD-YYYY') | date }} - {{ moment(picker.endDate).format('MM-DD-YYYY') | date }}
                </span>
                <span v-if="!(picker.startDate && picker.endDate)">
                  Select a date to load data
                </span>
                <v-icon>mdi-calendar</v-icon>
              </div>
            </template>
          </date-range-picker>
        </v-col>
      </v-row>
      <v-row class="contentWrapper">
        <v-col v-if="isLoaded">
          <v-container fluid>
            <v-row>
              <v-col
                cols="12"
                md="3"
                lg="3"
              >
                <div class="numberStatsWrapper">
                  <div class="iconPart aqua">
                    <v-icon>mdi-message-reply-text</v-icon>
                  </div>
                  <div class="dataPart">
                    <p class="stat">
                      {{ summarisedStats.sms }}
                    </p>
                    <p class="statLabel">
                      Total SMS Messages
                    </p>
                  </div>
                </div>
              </v-col>
              <v-col
                cols="12"
                md="3"
                lg="3"
              >
                <div class="numberStatsWrapper">
                  <div class="iconPart green">
                    <v-icon>mdi-message-image</v-icon>
                  </div>
                  <div class="dataPart">
                    <p class="stat">
                      {{ summarisedStats.mms }}
                    </p>
                    <p class="statLabel">
                      Total MMS Messages
                    </p>
                  </div>
                </div>
              </v-col>
              <v-col
                cols="12"
                md="3"
                lg="3"
              >
                <div class="numberStatsWrapper">
                  <div class="iconPart blue">
                    <v-icon>mdi-cellphone</v-icon>
                  </div>
                  <div class="dataPart">
                    <p class="stat">
                      {{ summarisedStats.calls }}
                    </p>
                    <p class="statLabel">
                      Total Voice Call
                    </p>
                  </div>
                </div>
              </v-col>
              <v-col
                cols="12"
                md="3"
                lg="3"
              >
                <div class="numberStatsWrapper">
                  <div class="iconPart orange">
                    <v-icon>mdi-clipboard-text</v-icon>
                  </div>
                  <div class="dataPart">
                    <p class="stat">
                      {{ summarisedStats.surveys }}
                    </p>
                    <p class="statLabel">
                      Total Surveys
                    </p>
                  </div>
                </div>
              </v-col>
              <v-col
                cols="12"
                md="8"
                lg="8"
              >
                <div class="lineChartWrapper">
                  <div id="chart">
                    <apexchart
                      type="area"
                      height="350"
                      :options="chartOptions"
                      :series="series"
                    />
                  </div>
                </div>
              </v-col>
              <v-col
                cols="12"
                md="4"
                lg="4"
              >
                <div class="balanceWrapper">
                  <span class="balanceTopIcon">
                    <div class="balanceTitle">
                      Balance Summary
                    </div>
                    <v-icon>
                      mdi-currency-usd
                    </v-icon> {{ userBalance.totalBalance }}
                    <span />
                  </span>
                  <div class="messageRow">
                    <v-icon>mdi-message-processing</v-icon> &nbsp; SMS Remaining: {{ userBalance.totalSMS }}
                  </div>
                  <div class="messageRow">
                    <v-icon>mdi-message-image</v-icon> &nbsp; MMS Remaining: {{ userBalance.totalMMS }}
                  </div>
                </div>
              </v-col>
              <v-col
                cols="12"
                md="4"
                lg="4"
              >
                <div class="pieChartWrapper">
                  <p class="messageStatTitle">
                    All SMS Statistics
                  </p>
                  <apex-pie-chart
                    :labels="smsChart.labels"
                    :series="smsChart.series"
                    :colors="smsChart.colors"
                  />
                </div>
              </v-col>
              <v-col
                cols="12"
                md="4"
                lg="4"
              >
                <div class="pieChartWrapper">
                  <p class="messageStatTitle">
                    All MMS Statistics
                  </p>
                  <apex-pie-chart
                    :labels="mmsChart.labels"
                    :series="mmsChart.series"
                    :colors="mmsChart.colors"
                  />
                </div>
              </v-col>
              <v-col
                cols="12"
                md="4"
                lg="4"
              >
                <div class="pieChartWrapper">
                  <p class="messageStatTitle">
                    All Surveys Statistics
                  </p>
                  <apex-pie-chart
                    :labels="surveyChart.labels"
                    :series="surveyChart.series"
                    :colors="surveyChart.colors"
                  />
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
        <v-col
          v-if="!isLoaded"
          class="selectText"
        >
          <p>Select a date range to load data</p>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar
      v-model="snackbar"
    >
      {{ notification }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import HttpConnector from '../../../utils/http-connector'
  import AuthService from '@/services/AuthService'
  import DateRangePicker from 'vue2-daterange-picker'
  import Vue from 'vue'
  import VueApexCharts from 'vue-apexcharts'
  import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
  import moment from 'moment'
  const auth = new AuthService()
  Vue.use(VueApexCharts)

  Vue.component('apexchart', VueApexCharts)

  export default {
    components: {
      DateRangePicker,
      ApexPieChart: () => import('../components/ApexPieChart'),
    },
    data: () => ({
      httpConnector: new HttpConnector(),
      apiEndpoint: process.env.VUE_APP_API_BASE_URL,
      apiCode: process.env.VUE_APP_API_CODE,
      isOverlayVisible: false,
      notification: '',
      snackbar: false,
      dateRange: '',
      moment,
      series: [
        {
          name: 'SMS',
          data: [28, 29, 33, 36, 32, 32, 33, 17, 38, 15, 18, 19, 17],
        },
        {
          name: 'MMS',
          data: [12, 11, 14, 18, 17, 13, 13, 8, 17, 27, 12, 31, 28],
        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
          toolbar: {
            show: false,
          },
        },
        colors: ['#77B6EA', 'rgb(0, 161, 0)'],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: 'smooth',
        },
        title: {
          text: 'SMS and MMS daily sent report',
          align: 'left',
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        markers: {
          size: 1,
        },
        xaxis: {
          categories: ['1-Dec', '2-Dec', '3-Dec', '4-Dec', '5-Dec', '6-Dec', '7-Dec', '8-Dec', '9-Dec', '10-Dec', '11-Dec', '12-Dec', '13-Dec'],
          title: {
            text: 'Days',
          },
        },
        yaxis: {
          title: {
            text: 'Count',
          },
          min: 5,
          max: 40,
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          floating: true,
          offsetY: -25,
          offsetX: -5,
        },
      },
      smsChart: {
        series: [44, 55, 13],
        labels: ['Sent', 'Delivered', 'Failed', 'Undelivered'],
        colors: ['#6edb79', '#4555ad', '#d10426', '#e8832a'],
      },
      mmsChart: {
        series: [87, 13],
        labels: ['Sent', 'Delivered', 'Failed', 'Undelivered'],
        colors: ['#6edb79', '#4555ad', '#d10426', '#e8832a'],
      },
      surveyChart: {
        series: [44, 31],
        labels: ['Sent', 'Recieved'],
        colors: ['#6edb79', '#4555ad'],
      },
      isLoaded: false,
      username: '',
      stats: {
        sms: [],
        mms: [],
        survey: {},
        calls: [],
      },
      summarisedStats: {
        calls: 0,
        sms: 0,
        mms: 0,
        surveys: 0,
      },
      userBalance: {
        totalBalance: 0,
        totalMMS: 0,
        totalSMS: 0,
      },
    }),

    async created () {
      this.username = localStorage.getItem('username')
    },

    asyncComputed: {
      async user () {
        const user = await auth.getUser()
        return user.profile
      },
    },

    methods: {
      updateValues () {
        if (this.dateRange.startDate && this.dateRange.endDate) {
          // Fetch data for the new date range
          this.fetchStats()
        }
      },
      async fetchStats () {
        this.isOverlayVisible = true
        try {
          const data = {
            StartDate: moment(this.dateRange.startDate).format('MM/DD/YYYY'),
            EndDate: moment(this.dateRange.endDate).format('MM/DD/YYYY'),
            Username: this.username,
          }
          const apiResponses = await Promise.all([
            this.httpConnector.makeRequest('post', `${this.apiEndpoint}smscount?code=${this.apiCode}`, data),
            this.httpConnector.makeRequest('post', `${this.apiEndpoint}mmscount?code=${this.apiCode}`, data),
            this.httpConnector.makeRequest('post', `${this.apiEndpoint}surveyresponsecount?code=${this.apiCode}`, data),
            this.httpConnector.makeRequest('post', `${this.apiEndpoint}surveysentcount?code=${this.apiCode}`, data),
            this.httpConnector.makeRequest('post', `${this.apiEndpoint}voicecallcount?code=${this.apiCode}`, data),
            this.httpConnector.makeRequest('get', `${this.apiEndpoint}user/balance?code=${this.apiCode}`, data),
          ])
          this.stats.sms = apiResponses[0].data
          this.stats.mms = apiResponses[1].data
          this.stats.survey.recieved = apiResponses[2].data
          this.stats.survey.sent = apiResponses[3].data
          this.stats.calls = apiResponses[4].data
          this.userBalance = {
            totalBalance: parseFloat(apiResponses[5].data.totalBalance).toFixed(2),
            totalMMS: apiResponses[5].data.totalMMS,
            totalSMS: apiResponses[5].data.totalSMS,
          }
          this._generateSummarisedStats()
          this.prepareCharts()
          this.isLoaded = true
          this.isOverlayVisible = false
        } catch (error) {
          this.isOverlayVisible = false
          this.notification = 'Unable to load data'
          this.snackbar = true
        }
      },
      _generateSummarisedStats () {
        this.summarisedStats = {
          calls: 0,
          sms: 0,
          mms: 0,
          surveys: 0,
        }
        this.stats.calls.forEach(call => {
          if (call.VoiceCallCount && !isNaN(parseInt(call.VoiceCallCount))) {
            this.summarisedStats.calls += parseInt(call.VoiceCallCount)
          }
        })
        this.stats.sms.forEach(sms => {
          if (sms.SMSCount && !isNaN(parseInt(sms.SMSCount))) {
            this.summarisedStats.sms += parseInt(sms.SMSCount)
          }
        })
        this.stats.mms.forEach(mms => {
          if (mms.SMSCount && !isNaN(parseInt(mms.SMSCount))) {
            this.summarisedStats.mms += parseInt(mms.SMSCount)
          }
        })
        this.stats.survey.sent.forEach(survey => {
          if (survey.SurveyCount && !isNaN(parseInt(survey.SurveyCount))) {
            this.summarisedStats.surveys += parseInt(survey.SurveyCount)
          }
        })
      },
      prepareLineChart () {
        const dateWiseMessages = {}
        // Iterate through all sms stats
        this.stats.sms.forEach(smsStat => {
          if (smsStat.StatusMessage === 'sent') {
            const formattedDate = moment(smsStat.Date).format('DD/MM/YY')
            if (!dateWiseMessages[formattedDate]) {
              dateWiseMessages[formattedDate] = {
                sms: 0,
                mms: 0,
              }
            }
            dateWiseMessages[formattedDate].sms = smsStat.SMSCount
          }
        })
        // Iterate through all mms stats
        this.stats.mms.forEach(mmsStat => {
          if (mmsStat.StatusMessage === 'sent') {
            const formattedDate = moment(mmsStat.Date).format('DD/MM/YY')
            if (!dateWiseMessages[formattedDate]) {
              dateWiseMessages[formattedDate] = {
                sms: 0,
                mms: 0,
              }
            }
            dateWiseMessages[formattedDate].mms = mmsStat.MMSCount
          }
        })
        // Convert to array
        let messagesStats = Object.keys(dateWiseMessages).map(d => ({
          date: d,
          smsCount: dateWiseMessages[d].sms,
          mmsCount: dateWiseMessages[d].mms,
        }))
        messagesStats = messagesStats.sort((e1, e2) => new Date(moment(e1.date, 'DD/MM/YYYY')) - new Date(moment(e2.date, 'DD/MM/YYYY')))
        this.chartOptions.xaxis.categories = messagesStats.map(mStats => mStats.date)
        this.series = [{
          name: 'SMS',
          data: messagesStats.map(mStats => mStats.smsCount),
        }, {
          name: 'MMS',
          data: messagesStats.map(mStats => mStats.mmsCount),
        }]
        // Determine min and max
        this.chartOptions.yaxis.min = 0
        this.chartOptions.yaxis.max = 0
        messagesStats.forEach(stat => {
          if (stat.smsCount < this.chartOptions.yaxis.min) {
            this.chartOptions.yaxis.min = stat.smsCount
          }
          if (stat.mmsCount < this.chartOptions.yaxis.min) {
            this.chartOptions.yaxis.min = stat.mmsCount
          }
          if (stat.mmsCount > this.chartOptions.yaxis.max) {
            this.chartOptions.yaxis.max = stat.mmsCount
          }
          if (stat.smsCount > this.chartOptions.yaxis.max) {
            this.chartOptions.yaxis.max = stat.smsCount
          }
        })
        // Add 30% offset to max
        this.chartOptions.yaxis.max = Math.ceil(((this.chartOptions.yaxis.max * 130) / 100))
      },
      preparePieCharts () {
        // For sms
        const totalSmsStats = {
          sent: 0,
          delivered: 0,
          failed: 0,
          undelivered: 0,
        }
        this.stats.sms.forEach(stat => {
          if (stat.StatusMessage === 'delivered') {
            totalSmsStats.delivered += stat.SMSCount
          }
          if (stat.StatusMessage === 'sent') {
            totalSmsStats.sent += stat.SMSCount
          }
          if (stat.StatusMessage === 'failed') {
            totalSmsStats.failed += stat.SMSCount
          }
          if (stat.StatusMessage === 'undelivered') {
            totalSmsStats.undelivered += stat.SMSCount
          }
        })
        this.smsChart = {
          series: Object.values(totalSmsStats),
          labels: ['Sent', 'Delivered', 'Failed', 'Undelivered'],
          colors: ['#6edb79', '#4555ad', '#d10426', '#e8832a'],
        }
        const totalMmsStats = {
          sent: 0,
          delivered: 0,
          failed: 0,
          undelivered: 0,
        }
        this.stats.mms.forEach(stat => {
          if (stat.StatusMessage === 'delivered') {
            totalMmsStats.delivered += stat.SMSCount
          }
          if (stat.StatusMessage === 'sent') {
            totalMmsStats.sent += stat.SMSCount
          }
          if (stat.StatusMessage === 'failed') {
            totalMmsStats.failed += stat.SMSCount
          }
          if (stat.StatusMessage === 'undelivered') {
            totalMmsStats.undelivered += stat.SMSCount
          }
        })
        this.mmsChart = {
          series: Object.values(totalMmsStats),
          labels: ['Sent', 'Delivered', 'Failed', 'Undelivered'],
          colors: ['#6edb79', '#4555ad', '#d10426', '#e8832a'],
        }
        const surveyStats = {
          sent: this.stats.survey.sent.length ? this.stats.survey.sent[0].SurveyCount : 0,
          recieved: this.stats.survey.recieved.length ? this.stats.survey.recieved[0].SurveyCount : 0,
        }
        this.surveyChart = {
          series: Object.values(surveyStats),
          labels: ['Sent', 'Recieved'],
          colors: ['#6edb79', '#4555ad'],
        }
      },
      prepareCharts () {
        this.prepareLineChart()
        this.preparePieCharts()
      },
      checkOpen () {

      },

    },
  }

</script>
<style lang="scss">
.datePicker {
  border: none;
  width: 310px;
  height: 50px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 4px 4px 15px rgba(0,0,0,0.12);
  display: flex;
  justify-content: space-between;
  border: 2px solid rgb(241, 181, 30);
  align-items: center;
  padding: 0 18px;
  span {
    font-size: 1.1rem;
    font-weight: 500;
  }
}
.form-control {
  &.reportrange-text {
    border: none;
    background-color: rgba(240, 248, 255, 0);
  }
}
.actionBarDateRangePickerWrapper {
  display: flex;
  justify-content: flex-end;
}
.contentWrapper {
  min-height: 70vh;
}
.lineChartWrapper {
  background-color: white;
  min-height: 360px;
  border-radius: 8px;
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.075);
  padding: 20px 18px;
}
.balanceWrapper {
  background: rgb(204,127,37);
background: linear-gradient(135deg, rgba(204,127,37,1) 0%, rgba(255,176,13,1) 54%, rgba(220,213,54,1) 100%);
  min-height: 400px;
  border-radius: 8px;
  color: rgb(255, 255, 255);
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.075);
  padding: 20px 18px;
  * {
    color: rgb(255, 255, 255)!important;
  }
  .balanceTopIcon {
    font-size: 2.5rem;
    * {
    font-size: 3.5rem;
  }
  }
  .messageRow {
    font-size: 1.2rem;
    margin-top: 15px;
    margin-left: 15px;
  }
  .balanceTitle {
    margin-top: 15px;
    margin-left: 15px;
    margin-bottom: 6rem;
    font-size: 2rem;
  }
}
.pieChartWrapper {
  background-color: white;
  min-height: 360px;
  border-radius: 8px;
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.075);
  padding: 15px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .messageStatTitle {
    font-size: 1.4rem;
  }
}
.selectText {
    width: 100%;
    height: 70vh;
    display: flex;
  justify-content: center;
  align-items: center;
  p {
    font-size: 1.8rem;
    color: rgb(199, 199, 199);
  }
  }
  .numberStatsWrapper {
    min-height: 140px;
  background-color: white;
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.075);

    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 2rem;
    .iconPart {
      width: 88px;
      height: 88px;
      border-radius: 8rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 5rem;
      &.blue {
        background-color: rgb(225, 227, 255)!important;
      * {
        color: rgb(47, 0, 255);
      font-size: 2rem;
      }
      }
      &.aqua {
        background-color: rgb(225, 255, 255)!important;
      * {
        color: aqua;
      font-size: 2rem;
      }
      }
      &.green {
        background-color: rgb(228, 255, 225)!important;
      * {
        color: rgb(0, 228, 57);
      font-size: 2rem;
      }
      }
      &.orange {
        background-color: rgb(255, 242, 225)!important;
      * {
        color: rgb(255, 196, 0);
      font-size: 2rem;
      }
      }
    }
    .dataPart {
      padding-left: 1rem;
      flex: 7;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: flex-start;
      .stat {
        font-size: 1.7rem;
        font-weight: 600;
        color: black;
        margin-bottom: 5px;
      }
      .statLabel {
        font-size: 1rem;
        margin-bottom: 0;
        // font-weight: 600;
        color: rgb(124, 124, 124);
      }
    }
  }
</style>
